/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Century Bold';
  src: url('assets/fonts/century-gothic-bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Century';
  src: url('assets/fonts/century-gothic-std.otf') format('opentype');
}

@font-face {
  font-family: 'Ubuntu';
  src: url('assets/fonts/Ubuntu-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Ubuntu Bold';
  src: url('assets/fonts/Ubuntu-Bold.ttf') format('opentype');
}

input, select, textarea, button, a, li, tr{
  outline: none !important;
}
a{
  cursor: pointer;
}
.ubuntu{
  font-family: 'Ubuntu' !important;
}

.bton{
  font-size: 15px;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 5px 30px;
  font-family: 'Century';
  font-weight: bold;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.button1{
  background: #386E49;
  color: #ffffff;
  border: solid 1px #386E49;
  &:hover{
      border: solid 1px #386E49;
      background: #ffffff;
      color: #386E49;
  }
}
.button2{
  background: #C1272D;
  color: #ffffff;
  border: solid 1px #C1272D;
  &:hover{
      border: solid 1px #C1272D;
      background: #ffffff;
      color: #C1272D;
  }
}
.button3{
  background: #0071BC;
  color: #ffffff;
  border: solid 1px #0071BC;
  &:hover{
      border: solid 1px #0071BC;
      background: #ffffff;
      color: #0071BC;
  }
}

b{
  font-family: 'Century Bold' !important;
}

label{
  margin-top: 10px;
}

.label1{
  font-style: italic;
  font-family: 'Ubuntu';
  color: #666666;
}


.link1{
  color: #FFFFFF;
  font-family: 'Century Bold';
  &:hover{
    color: #868686;
  }
}
.link2{
  color: #4D4D4D;
  font-family: 'Century Bold';
  &:hover{
    color: #000000;
  }
}
.link3{
  color: #db2222;
  font-family: 'Century Bold';
  &:hover{
    color: #4D4D4D;
  }
}


.title1{
  color: #ffffff;
  font-family: 'Century Bold';
}
.title2{
  color: #4D4D4D;
  font-family: 'Century Bold';
}
.title3{
  color: #4D4D4D;
  font-family: 'Century';
}


.text1{
  color: #ffffff;
  font-family: 'Century Bold';
}
.text2{
  color: #4D4D4D;
  font-family: 'Century';
}

table{
  thead{
      tr{
          background: transparent !important;
          th{
              border-top: transparent !important;
              font-size: 15px;
              font-family: 'Century Bold';
              font-style: italic;
              color: #999999 !important;
              text-transform: capitalize;
          }
      }
  }
  tr{
      td{
          font-family: 'Century Bold';
          color: #4D4D4D;
          font-size: 12px;
      }
  }
}


@media screen and (max-width: 765px){
  button{
    margin-top: 10px;
  }
}
